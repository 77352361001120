import React, { useState } from "react";
import { Switch, Route, Redirect, BrowserRouter, useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AppsIcon from "@material-ui/icons/Apps";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { selectSignedIn, selectInitialized, selectError, selectMessage, selectUser } from "./model/reduxClientSlice";

// brand autoset start
// import AutorenewIcon from '@material-ui/icons/Autorenew';
// import PathLink, {
//     SIGNIN_PATH, BRAND_REVIEW_PATH, BRAND_CORRECTION_PATH, CATEGORY_REVIEW_PATH,
//     BRAND_REVIEW_AUTOSET_PATH, CATEGORY_CORRECTION_PATH
// } from "./model/pathLink";
import PathLink, {
    SIGNIN_PATH, BRAND_REVIEW_PATH, BRAND_CORRECTION_PATH, CATEGORY_REVIEW_PATH, CATEGORY_CORRECTION_PATH
} from "./model/pathLink";
// brand autoset end

import Client from "./model/client";
import UserRoles from "./model/userRoles";

import Alert from "./component/Alert";
import SignInPage from "./page/signin";
// brand autoset start
// import BrandAutosetProposals from "./page/brandAutosetProposals";
// brand autoset end
import ReviewPage from "./page/reviewPage";
import CorrectionPage from "./page/correctionPage";

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        fontFamily: ["Roboto", "Arial", "Helvetica", "sans-serif"].join(","),
    },

    // drawer
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: "nowrap",
        position: "relative",
    },

    drawerHeader: {
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },

    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(5) + 1,
        [theme.breakpoints.up("sm")]: { width: theme.spacing(7) + 1 },
    },

    drawerItemInactive: {
        background: "white",
        color: "#212121 !important",
    },

    drawerItemActive: {
        background: "#1976d21f",
        color: "#1976d2 !important",
    },

    drawerIconInactive: {
        color: "#0000008A !important",
    },

    drawerIconActive: {
        color: "#1976D2 !important",
    },

    drawerUserCard: {
        width: 200,
        marginTop: theme.spacing(1),
    },

    drawerUserName: {
        fontSize: 20,
        marginLeft: 16,
        width: "100%",
        letterSpacing: 0.26,
        marginTop: -5,
    },

    drawerUserEmail: {
        fontSize: 14,
        width: "100%",
        marginLeft: 16,
        letterSpacing: 0.24,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    drawerUserDetails: {
        float: "left",
        width: 130,
    },

    drawAvatarOpen: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        float: "left",
    },

    drawAvatarClose: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: 2,
    },

    drawerLogout: {
        position: "absolute",
        bottom: 20,
    },

    content: {
        flexGrow: 1,
    },

    // toolbar
    toolbar: {
        width: "100%",
        height: 50,
        padding: theme.spacing(2),
        backgroundColor: "#FAFAFA",
        fontWeight: "bold",
        fontSize: 18,
        borderBottom: "1px solid lightgray",
    },

    // pages
    pages: {
        flexGrow: 1,
        // maxHeight: 200,
        overflowY: "scroll",
        padding: theme.spacing(2),
    },
    pageView: {
        width: "100%",
    },

    alert: {
        position: "absolute",
        bottom: 50,
        width: 250,
        left: "50%",
        marginLeft: -125,
    },
}));

export default function App() {
    const user = useSelector(selectUser);
    const classes = useStyles();
    const signedIn = useSelector(selectSignedIn);
    const initialized = useSelector(selectInitialized);
    const error = useSelector(selectError);
    const message = useSelector(selectMessage);

    if (!initialized) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <main className={classes.content}>
                    LOADING..
                </main>
            </div>
        );
    }

    if (signedIn) {
        return (
            <div className={classes.root}>
                <BrowserRouter>
                    <CssBaseline />
                    <AppDrawer />
                    <div className={classes.content}>
                        <AppToolbar />
                        <div className={classes.pages}>
                            <div className={classes.pageView}>
                                <Switch>
                                    {/* brand autoset start {
                                        UserRoles.isSupervisor(user) ?
                                            <Route exact path={BRAND_REVIEW_AUTOSET_PATH}>
                                                <BrandAutosetProposals key={BRAND_REVIEW_AUTOSET_PATH} />
                                            </Route>
                                            : undefined
                                    } brand autoset end */}
                                    {
                                        UserRoles.isSupervisor(user) || UserRoles.isBrandReviewer(user) ?
                                            <Route exact path={BRAND_REVIEW_PATH}>
                                                <ReviewPage key={BRAND_REVIEW_PATH} isBrand={true} />
                                            </Route> : undefined
                                    }
                                    {
                                        UserRoles.isSupervisor(user) || UserRoles.isBrandCorrector(user) ?
                                            <Route exact path={BRAND_CORRECTION_PATH}>
                                                <CorrectionPage key={BRAND_CORRECTION_PATH} isBrand={true} />
                                            </Route> : undefined
                                    }
                                    {
                                        UserRoles.isSupervisor(user) || UserRoles.isCategoryReviewer(user) ?
                                            <Route exact path={CATEGORY_REVIEW_PATH}>
                                                <ReviewPage key={CATEGORY_REVIEW_PATH} isBrand={false} />
                                            </Route> : undefined
                                    }
                                    {
                                        UserRoles.isSupervisor(user) || UserRoles.isCategoryCorrector(user) ?
                                            <Route exact path={CATEGORY_CORRECTION_PATH}>
                                                <CorrectionPage key={CATEGORY_CORRECTION_PATH} isBrand={false} />
                                            </Route> : undefined
                                    }
                                    <RouteRedirect user={user} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </BrowserRouter>
                {error ? (
                    <Alert severity="error" className={classes.alert}>
                        {error}
                    </Alert>
                ) : undefined}
                {message ? (
                    <Alert severity="success" className={classes.alert}>
                        {message}
                    </Alert>
                ) : undefined}
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.content}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={SIGNIN_PATH}>
                            <SignInPage />
                        </Route>
                        <Route>
                            <Redirect to={SIGNIN_PATH} />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
            {error ? (
                <Alert severity="error" className={classes.alert}>
                    {error}
                </Alert>
            ) : undefined}

        </div>
    );
}

export function AppInitializer() {
    const dispatch = useDispatch();
    dispatch(Client.refresh());
    return (
        <div style={{ display: "none" }} />
    );
}

function AppDrawer() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(selectUser);
    const [open, setOpen] = useState(false);

    function handleClick(route) {
        return () => { history.push(route); };
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.drawerHeader}>
                {open ? <div className={classes.drawerUserCard}>
                    <Avatar
                        alt="avatar@2x.png"
                        src="/images/avatar@2x.png"
                        className={classes.drawAvatarOpen}
                    />
                    <div className={classes.drawerUserDetails}>
                        <div className={classes.drawerUserName}>{user.name.split(" ")[0]}</div>
                        <div className={classes.drawerUserEmail}>{user.email}</div>
                    </div>
                </div> : undefined}
                <IconButton onClick={() => { setOpen(!open); }} size="small">
                    {open ?
                        <ChevronLeftIcon /> :
                        <Avatar
                            alt="avatar@2x.png"
                            src="/images/avatar@2x.png"
                            className={classes.drawAvatarClose}
                        />
                    }
                </IconButton>
            </div>
            <Divider />
            <List style={{ padding: 0 }}>
                {
                    UserRoles.isSupervisor(user) || UserRoles.isBrandReviewer(user) ?
                        <AppDrawerOption
                            icon={<LocalOfferIcon />}
                            text="Brand check"
                            path={BRAND_REVIEW_PATH}
                            classes={classes}
                            tooltip="Brand check"
                            onClick={handleClick(BRAND_REVIEW_PATH)}
                        /> : undefined
                }
                {
                    UserRoles.isSupervisor(user) || UserRoles.isBrandCorrector(user) ?
                        <AppDrawerOption
                            icon={<CheckCircleOutlineIcon />}
                            text="Brand correction"
                            path={BRAND_CORRECTION_PATH}
                            classes={classes}
                            tooltip="Brand correction"
                            onClick={handleClick(BRAND_CORRECTION_PATH)}
                        /> : undefined
                }
                {/* brand autoset start {
                    UserRoles.isSupervisor(user) ?
                        <AppDrawerOption
                            icon={<AutorenewIcon />}
                            text="Brand autoset"
                            path={BRAND_REVIEW_AUTOSET_PATH}
                            classes={classes}
                            tooltip="Brand autoset"
                            onClick={handleClick(BRAND_REVIEW_AUTOSET_PATH)}
                        />
                        : undefined
                } brand autoset end */}
            </List>
            <Divider />
            <List style={{ padding: 0 }}>
                {
                    UserRoles.isSupervisor(user) || UserRoles.isCategoryReviewer(user) ?
                        <AppDrawerOption
                            icon={<AppsIcon />}
                            text="Category check"
                            path={CATEGORY_REVIEW_PATH}
                            classes={classes}
                            tooltip="Category check"
                            onClick={handleClick(CATEGORY_REVIEW_PATH)}
                        /> : undefined
                }
                {
                    UserRoles.isSupervisor(user) || UserRoles.isCategoryCorrector(user) ?
                        <AppDrawerOption
                            icon={<AssignmentTurnedInIcon />}
                            text="Category correction"
                            path={CATEGORY_CORRECTION_PATH}
                            classes={classes}
                            tooltip="Category correction"
                            onClick={handleClick(CATEGORY_CORRECTION_PATH)}
                        /> : undefined
                }
            </List>
            <div className={classes.drawerLogout}>
                <AppDrawerOption
                    icon={<ExitToAppIcon />}
                    text="Logout"
                    path=""
                    classes={classes}
                    tooltip="Logout"
                    onClick={async () => { await Client.signOut(dispatch); }}
                />
            </div>
        </Drawer>
    );
}

function AppDrawerOption({ icon, text, onClick, path, classes, tooltip }) {
    const location = useLocation();
    return (
        <Tooltip title={tooltip} aria-label={tooltip}>
            <ListItem
                button
                key={text}
                onClick={onClick}
                className={`${location.pathname === path ? classes.drawerItemActive : classes.drawerItemInactive}`}
            >
                <ListItemIcon className={`${location.pathname === path ? classes.drawerIconActive : classes.drawerIconInactive}`}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
            </ListItem>
        </Tooltip>
    );
}

function AppToolbar() {
    const classes = useStyles();
    const location = useLocation();
    // todo: add links
    return (
        <div className={classes.toolbar}>
            {PathLink.decode(location.pathname)[0].text}
        </div>
    );
}

function RouteRedirect({ user }) {
    if (UserRoles.isSupervisor(user) || UserRoles.isBrandReviewer(user))
        return <Route><Redirect to={BRAND_REVIEW_PATH} /></Route>;

    if (UserRoles.isBrandCorrector(user))
        return <Route><Redirect to={BRAND_CORRECTION_PATH} /></Route>;

    if (UserRoles.isCategoryReviewer(user))
        return <Route><Redirect to={CATEGORY_REVIEW_PATH} /></Route>;

    if (UserRoles.isCategoryCorrector(user))
        return <Route><Redirect to={CATEGORY_CORRECTION_PATH} /></Route>;

    return <Route path='*'>User role has not been set. Please contact site admin.</Route>
}