import React from "react";

import {
    createStyles,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
} from "@material-ui/core";
import { Select } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

export default function Selector({
    id,
    label,
    value,
    selections,
    onChange,
    width = null,
    disabled = null,
}) {
    const classes = useStyles();
    const formWidth = width ? width : 100;

    return (
        <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
            style={{ width: formWidth }}
            disabled={disabled}
        >
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                labelId={`${id}-select-label`}
                id={id}
                onChange={(event) => onChange(event.target.value)}
                value={value}
                label={label}
            >
                {selections.map((current, i) => {
                    return (
                        <MenuItem
                            id={`${id}-${i}`}
                            key={`${id}-${i}`}
                            value={i}
                        >
                            {current.length === 0 ? "None" : current}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
