import axios from "axios";
import cookie from "cookie";
import JwtDecode from "jwt-decode";

import { showError, setSignIn, setInitialized, setSignOut } from "./reduxClientSlice";

const SIGNEDIN_COOKIE = "sic";
const AUTH_API_URL = "https://pirt-staging.blinkreceipt.com";
const ANNOTATION_API_URL = "https://pirt-staging.blinkreceipt.com";

let token = null;

export default class Client {
    static async signIn(email, password, dispatch) {
        const mail = email.trim();
        const pass = password.trim();
        if (mail.length === 0 || pass.length === 0) {
            showError("All fields are required", dispatch);
            return;
        }
        const url = `${AUTH_API_URL}/api/session`;
        const data = { email: mail, password: pass };
        try {
            const res = await axios.post(url, data, { withCredentials: true });
            token = res.data.token;
            setCookie();
            dispatch(setSignIn({ user: res.data }));
        } catch (err) {
            let error =
                err && err.response && err.response.data ? err.response.data : `${err}`;
            showError(error, dispatch);
        }
    }

    static refresh() {
        return async (dispatch) => {
            const cookies = cookie.parse(document.cookie);
            if (cookies[SIGNEDIN_COOKIE] === undefined) {
                dispatch(setInitialized({}));
                return;
            }

            const url = `${AUTH_API_URL}/api/session`;
            try {
                const res = await axios.put(url, { include_roles: true }, { withCredentials: true });
                token = res.data.token;
                dispatch(setSignIn({ user: res.data }));
                setCookie();
            } catch (err) {
                dropCookie();
                let error =
                    err && err.response && err.response.data
                        ? err.response.data
                        : `${err}`;
                console.log(error);
                dispatch(setInitialized({}));
            }
        };
    }

    static async signOut(dispatch) {
        if (token === null) {
            localStorage.clear();
            dropCookie();
            dispatch(setSignOut({}));
            return;
        }

        const cookies = cookie.parse(document.cookie);
        if (cookies[SIGNEDIN_COOKIE] === undefined) {
            token = null;
            localStorage.clear();
            dispatch(setSignOut({}));
            return;
        }

        const url = `${AUTH_API_URL}/api/session`;
        try {
            await axios.delete(url, {
                withCredentials: true,
                headers: { authorization: `Bearer ${token}` },
            });
        } catch (err) { }
        token = null;
        localStorage.clear();
        dispatch(setSignOut({}));
        dropCookie();
    }

    static async authPost(path, data, dispatch) {
        return authorizedRequest(this, "post", path, data, dispatch);
    }

    static async authPut(path, data, dispatch) {
        return authorizedRequest(this, "put", path, data, dispatch);
    }

    static async authGet(path, dispatch) {
        return authorizedRequest(this, "get", path, null, dispatch);
    }

    static async authDelete(path, dispatch) {
        return authorizedRequest(this, "delete", path, null, dispatch);
    }

    static async post(token, path, data, dispatch) {
        return request(token, "post", path, data, dispatch);
    }

    static async put(token, path, data, dispatch) {
        return request(token, "put", path, data, dispatch);
    }

    static async get(token, path, data, dispatch) {
        return request(token, "get", path, data, dispatch);
    }
}

async function request(token, method, path, data, dispatch) {
    const url = `${ANNOTATION_API_URL}/api/${path}`;
    try {
        const payload = {
            method,
            url,
        };
        if (token) {
            payload.headers = { authorization: `Bearer ${token}` };
        }
        if (data) {
            if (method === "get") {
                payload.params = data;
            } else {
                payload.data = data;
            }
        }
        let res = await axios(payload);
        return res.data;
    } catch (err) {
        const error = err && err.response && err.response.data ? err.response.data : `${err}`;
        showError(error, dispatch);
        return null;
    }
}

async function authorizedRequest(client, method, path, data, dispatch) {

    if (shouldRefresh(client)) {
        const cookies = cookie.parse(document.cookie);
        if (cookies[SIGNEDIN_COOKIE] === undefined) {
            dispatch(setSignOut({}));
            showError("Not authorized", dispatch);
            return;
        }

        const url = `${AUTH_API_URL}/api/session`;
        try {
            const res = await axios.put(url, { include_roles: false }, { withCredentials: true });
            if (!token) dispatch(setSignIn({ user: null }));
            token = res.data.token;
            setCookie();
        } catch (err) {
            dropCookie();
            dispatch(setSignOut({}));
            showError("Not authorized", dispatch);
            return;
        }
    }

    const url = `${ANNOTATION_API_URL}/api${path}`;
    try {
        let res = await axios({
            method,
            url,
            data,
            headers: { authorization: `Bearer ${token}` },
        });
        return res.data;
    } catch (err) {
        const error = err && err.response && err.response.data ? err.response.data : `${err}`;
        showError(error, dispatch);
        throw error;
    }
}

function setCookie() {
    document.cookie = `${SIGNEDIN_COOKIE}=true; path=/;`;
}

function dropCookie() {
    document.cookie = `${SIGNEDIN_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

function shouldRefresh(client) {
    if (token === null) return true;
    try {
        const { exp } = JwtDecode(token);
        return Date.now() > exp * 1000;
    } catch {
        return true;
    }
}
