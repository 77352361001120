export default class UserRoles {
    static isAdmin(user) {
        return user.roles.includes("admin");
    }

    static isSupervisor(user) {
        return user.roles.includes("supervisor");
    }

    static isBrandReviewer(user) {
        return user.roles.includes("brand_checker");
    }

    static isBrandCorrector(user) {
        return user.roles.includes("brand_corrector");
    }
    
    static isCategoryReviewer(user) {
        return user.roles.includes("taxonomy_checker");
    }

    static isCategoryCorrector(user) {
        return user.roles.includes("taxonomy_corrector");
    }
}
