export const DATA_FILTER_RSD = "rsd";
export const DATA_FILTER_MERCHANT = "merchant";
const DATA_FILTER_SALES = "sales";
export const DATA_FILTER_CATEGORY = "category";
const DATA_FILTER_RSD_CHAR_ID = "rsd_char_id";
const DATA_FILTER_RSD_CHAR = "rsd_char";

export default class DataFilter {
    constructor(prefix) {
        this.prefix = prefix;
        this.rsdFilter = localStorage.getItem(parm(prefix, DATA_FILTER_RSD)) || "";
        this.merchantFilter = localStorage.getItem(parm(prefix, DATA_FILTER_MERCHANT)) || "";
        this.salesFilter = localStorage.getItem(parm(prefix, DATA_FILTER_SALES)) || "";
        this.categoryFilter = localStorage.getItem(parm(prefix, DATA_FILTER_CATEGORY)) || "";
        this.rsdCharFilterId = parseInt(localStorage.getItem(parm(prefix, DATA_FILTER_RSD_CHAR_ID)) || "0");
        this.rsdCharFilter = localStorage.getItem(parm(prefix, DATA_FILTER_RSD_CHAR)) || "";
        this.numFilteredDataIds = 0;
    }

    clear() {
        this.setRSDFilter("");
        this.setMerchantFilter("");
        this.setSalesFilter("");
        this.setCategoryFilter("");
        this.setRSDCharFilter("", []);
        this.numFilteredDataIds = 0;
    }

    setRSDFilter(value) {
        this.rsdFilter = value;
        localStorage.setItem(parm(this.prefix, DATA_FILTER_RSD), value);
    }

    setMerchantFilter(value) {
        this.merchantFilter = value;
        localStorage.setItem(parm(this.prefix, DATA_FILTER_MERCHANT), value);
    }

    setSalesFilter(value) {
        this.salesFilter = value;
        localStorage.setItem(parm(this.prefix, DATA_FILTER_SALES), value);
    }

    setCategoryFilter(value) {
        this.categoryFilter = value;
        localStorage.setItem(parm(this.prefix, DATA_FILTER_CATEGORY), value);
    }

    setRSDCharFilter(id, rsdCharFilterList) {
        if (rsdCharFilterList.length === 0) {
            this.rsdCharFilterId = 0;
            this.rsdCharFilter = "";
            localStorage.setItem(parm(this.prefix, DATA_FILTER_RSD_CHAR_ID), "0");
            localStorage.setItem(parm(this.prefix, DATA_FILTER_RSD_CHAR), "");
            return;
        }

        if (id <= 0 || id >= rsdCharFilterList.length) {
            this.rsdCharFilterId = 0;
            this.rsdCharFilter = "";
            localStorage.setItem(parm(this.prefix, DATA_FILTER_RSD_CHAR_ID), "0");
            localStorage.setItem(parm(this.prefix, DATA_FILTER_RSD_CHAR), "");
            return;
        }

        this.rsdCharFilterId = id;
        this.rsdCharFilter = rsdCharFilterList[id];
        localStorage.setItem(parm(this.prefix, DATA_FILTER_RSD_CHAR_ID), `${id}`);
        localStorage.setItem(parm(this.prefix, DATA_FILTER_RSD_CHAR), rsdCharFilterList[id]);
    }

    rsdCharFilterList(data) {
        if (!data) return null;
        const filterList = [];

        data.forEach(x => {
            const rsd = x.receipt_short_description;
            if (rsd.length === 0) return;
            const char = rsd.charAt(0).toUpperCase();
            if (filterList.includes(char)) return;
            filterList.push(char);
        });

        if (filterList.length < 2)
            return null;

        filterList.sort();
        filterList.splice(0, 0, "");
        return filterList;
    }

    hasFilters() {
        return (
            this.rsdFilter.length > 0 ||
            this.merchantFilter.length > 0 ||
            this.salesFilter.length > 0 ||
            this.categoryFilter.length > 0 ||
            this.rsdCharFilterId > 0
        );
    }

    filterLabel() {
        const a = [];
        if (this.rsdFilter.length > 0) a.push("RSD");
        if (this.merchantFilter.length > 0) a.push("Merchant");
        if (this.salesFilter.length > 0) a.push("Count");
        if (this.categoryFilter.length > 0) a.push("Category");
        if (a.length === 0) return "ADD FILTER";
        return `FILTERED BY ${a.join(",")}`;
    }

    filter(data) {
        this.numFilteredDataIds = 0;
        if (data.length === 0)
            return [];

        if (!this.hasFilters())
            return [...Array(data.length).keys()];

        const rsd = this.rsdFilter.toLowerCase();
        const merchant = this.merchantFilter.toLowerCase();
        const sales = parseInt(this.salesFilter.length > 0 ? this.salesFilter : "0");
        const category = this.categoryFilter.toLowerCase();
        const rsdChar = this.rsdCharFilter;

        const filteredDataIds = data.reduce((prev, cur, idx) => {
            if (rsdChar.length > 0) {
                if (cur.receipt_short_description.charAt(0).toUpperCase() === rsdChar) prev.push(idx);
                return prev;
            }

            let valid = true;
            if (rsd.length > 0 && !cur.receipt_short_description.toLowerCase().includes(rsd))
                valid = false;

            if (merchant.length > 0 && !cur.merchant_name.toLowerCase().includes(merchant))
                valid = false;

            if (sales > 0 && cur.num_sales < sales)
                valid = false;

            if (category.length > 0 && !cur.category.toLowerCase().includes(category))
                valid = false;

            if (valid) prev.push(idx);
            return prev;
        }, []);

        this.numFilteredDataIds = filteredDataIds.length;
        return filteredDataIds;
    }

    filterSuggestions(data, filterType, filterString) {
        if (![DATA_FILTER_RSD, DATA_FILTER_MERCHANT, DATA_FILTER_CATEGORY].includes(filterType))
            return [];

        const filter = filterString.toLowerCase();
        const caseInsensitiveList = [];
        return data
            .filter(x => {
                let value;
                if (filterType === DATA_FILTER_RSD)
                    value = x.receipt_short_description.toLowerCase();
                else if (filterType === DATA_FILTER_MERCHANT)
                    value = x.merchant_name.toLowerCase();
                else if (filterType === DATA_FILTER_CATEGORY)
                    value = x.category.toLowerCase();
                else
                    return false;

                if (!value.includes(filter))
                    return false;

                if (caseInsensitiveList.includes(value))
                    return false;

                caseInsensitiveList.push(value);
                return true;
            })
            .map(x => {
                switch (filterType) {
                    case DATA_FILTER_RSD:
                        return x.receipt_short_description;
                    case DATA_FILTER_MERCHANT:
                        return x.merchant_name;
                    case DATA_FILTER_CATEGORY:
                        return x.category;
                    default:
                        return "";
                }
            });
    }

    filterCount() {
        return this.hasFilters() ? this.numFilteredDataIds : null;
    }
}

function parm(prefix, postfix) {
    return `${prefix}_${postfix}`;
}