import React, { useRef, useState } from "react";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useDispatch } from "react-redux";

import Loading from "../component/Loading";
import ReviewHeader from "./reviewHeader";
import ReviewTable from "./reviewTable";
import ModalBrandAutosetProposal from "./modalBrandAutosetProposal";
import Stats from "../model/stats";
import { BrandReviewer, CategoryReviewer } from "../model/mixinModels";
import DataFilter from "../model/dataFilter";
import Paginator from "../model/paginator";
import { showError, showMessage } from "../model/reduxClientSlice";

const useStyles = makeStyles((theme) => {
    const padding = 10;
    return createStyles({
        root: {
            width: "100%"
        },
        headerRoot: {
            width: "100%",
            height: 125,
            borderBottom: "1px solid lightgray",
        },
        headerContent: {
            height: 60,
        },
        filter: {
            float: "left",
            marginTop: 19,
        },
        filterCount: {
            float: "left",
            marginTop: 23,
            marginLeft: -5,
            backgroundColor: "rgb(33, 150, 243)",
            color: "white",
        },
        removeSpacing: {
            marginLeft: -20,
        },
        switches: {
            marginTop: padding,
        },
        title: {
            fontWeight: "bold",
        },
        reviewCheckbox: {
            marginTop: 10,
            marginRight: -15,
        },
        stats: {
            float: "left",
            marginLeft: 20,
            marginTop: 5,
            height: 25,
        },
        statsLabel: {
            float: "left",
            fontWeight: 600,
            marginTop: 2,
        },
        statsDivider: {
            float: "left",
            margin: "0 10px",
        },
        editCorrections: {
            float: "right",
            margin: "-5px 12px 0 0",
            width: 200,
        },
        save: {
            float: "right",
            margin: "-5px 18px 0 0",
            width: 119,
        },
        editSection: {
            float: "left",
            marginLeft: 8,
            padding: 1,
        },
        editButton: {
            marginLeft: padding,
        },
    });
});

export default function ReviewPage({ isBrand }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const brandPrefix = "brand_review";
    const categoryPrefix = "category_review";

    const reviewer = useRef(isBrand ? new BrandReviewer(brandPrefix) : new CategoryReviewer(categoryPrefix));
    const dataFilter = useRef(new DataFilter(isBrand ? brandPrefix : categoryPrefix));
    const paginator = useRef(new Paginator());

    const [stats, setStats] = useState(Stats.blankList());
    const [loading, setLoading] = useState(false);
    const [autosetData, setAutosetData] = useState(null);

    const [rows, setRows] = useState([]);
    const [numApproved, setNumApproved] = useState(0);
    const [numRejected, setNumRejected] = useState(0);
    const [filterCount, setFilterCount] = useState(null);
    const [rsdCharFilterList, setRSDCharFilterList] = useState(null);

    async function setPage(data) {
        paginator.current.setDataIds(dataFilter.current.filter(data));
        setRows(paginator.current.pageRows(reviewer.current.data));
        setStats(await Stats.getStats(reviewer.current.data, reviewer.current.state, dispatch));
        setNumApproved(reviewer.current.numApproved());
        setNumRejected(reviewer.current.numRejected());
        setFilterCount(dataFilter.current.filterCount());
        setRSDCharFilterList(dataFilter.current.rsdCharFilterList(data));
    }

    async function loadPage() {
        setLoading(true);
        const data = await reviewer.current.getData(dispatch);
        setLoading(false);
        if (!data) return;
        paginator.current.reset();
        setPage(data);
    }

    return (
        <div className={classes.root}>
            <ReviewHeader
                rows={rows}
                reviewer={reviewer.current}
                dataFilter={dataFilter.current}
                paginator={paginator.current}
                numApproved={numApproved}
                numRejected={numRejected}
                stats={stats}
                filterCount={filterCount}
                rsdCharFilterList={rsdCharFilterList}
                onReload={loadPage}
                onFilter={() => {
                    paginator.current.setDataIds(dataFilter.current.filter(reviewer.current.data));
                    setRows(paginator.current.pageRows(reviewer.current.data));
                    setFilterCount(dataFilter.current.filterCount());
                }}
                onSort={() => {
                    if (reviewer.current.dataLength() === 0) {
                        loadPage();
                        return;
                    }
                    const sorted = reviewer.current.sortData();
                    if (!sorted) return;
                    paginator.current.setDataIds(dataFilter.current.filter(reviewer.current.data));
                    setRows(paginator.current.pageRows(reviewer.current.data));
                }}
                onSave={async () => {
                    setLoading(true);
                    const success = await reviewer.current.saveReviews(dispatch);
                    if (success) {
                        loadPage();
                        return;
                    }
                    setLoading(false);
                }}
                onPageBack={() => {
                    const prevRows = paginator.current.prevPageRows(reviewer.current.data);
                    if (prevRows) setRows(prevRows);
                }}
                onPageForward={() => {
                    const nextRows = paginator.current.nextPageRows(reviewer.current.data);
                    if (nextRows) setRows(nextRows);
                }}
                onPageConfirmed={() => {
                    reviewer.current.confirmRows(rows);
                    setNumApproved(reviewer.current.numApproved());
                    setNumRejected(reviewer.current.numRejected());
                    setRows(paginator.current.pageRows(reviewer.current.data));
                }}
            />

            {loading ? (
                <Loading size={70} />
            ) : (
                <ReviewTable
                    rows={rows}
                    isBrand={isBrand}
                    onRowClick={(dataId, value) => {
                        const delta = reviewer.current.setApproved(dataId, value);
                        setNumApproved(numApproved + delta.numApproved);
                        setNumRejected(numRejected + delta.numRejected);
                        setRows(paginator.current.pageRows(reviewer.current.data));
                    }}
                    onAutoset={isBrand ? dataId => setAutosetData(reviewer.current.data[dataId]) : null}
                />
            )}

            {autosetData ? (
                <ModalBrandAutosetProposal
                    annotation={autosetData}
                    open={autosetData !== null}
                    onSave={async (annotation) => {
                        const result = await reviewer.current.sendBrandAutosetProposal(annotation, dispatch);
                        if (!result) {
                            showError("Autoset failed", dispatch);
                            return;
                        }
                        reviewer.current.removeAnnotationsByRSDPrefix(result.prefix, result.merchant);

                        await reviewer.current.data.length === 0 ? loadPage() : setPage(reviewer.current.data);
                        setAutosetData(null);
                        showMessage("Autoset successful", dispatch);
                    }}
                    onClose={() => {
                        setAutosetData(null);
                    }}
                />
            ) : undefined}

        </div>
    );
}