import React, { useState } from "react";
import MuiDataTable from "mui-datatables";
import TableCell from "@material-ui/core/TableCell";
import { Checkbox } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return createStyles({
        row: {
            cursor: "default",
            borderTop: "1px solid #E4E4E4",
        },
        rowEdited: {
            cursor: "default",
            borderTop: "1px solid #E4E4E4",
            backgroundColor: "#E8FBFB",
        },
        rowCellBold: {
            fontWeight: "bold",
            color: "#474749",
        },
        divider: {
            float: "left",
            margin: "0 5px",
            height: 20,
            background: "#212121",
        },
        taxonomy: {
            float: "left",
        },
        hoverButtons: {
            position: "absolute",
            top: 10,
            left: 5,
            bottom: 10,
        },
    });
});

export default function CorrectionTable({ rows, isBrand, onEdit, onRowChecked, onAllRowsChecked }) {
    let allChecked = true;
    for (let i = 0; i < rows.length; i++) {
        if (typeof rows[i].selected === "undefined") {
            allChecked = false;
            break;
        }
    }

    const columns = [
        {
            name: "id",
            label: "",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
                customHeadRender: ({ index, ...column }) => {
                    return (
                        <TableCell key={index} style={cbColStyle}>
                            <Checkbox
                                id={"checkbox-all"}
                                checked={allChecked}
                                onChange={(e) => {
                                    onAllRowsChecked(e.target.checked);
                                }}
                                style={cbStyle}
                                color="primary"
                            />
                        </TableCell>
                    );
                },
            },
        },
        {
            name: "merchant_name",
            label: "Retailer",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
        {
            name: "receipt_short_description",
            label: "RSD",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
        {
            name: "current_brand",
            label: "Brand",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
    ];

    isBrand ? appendBrandColumns(columns) : appendCategoryColumns(columns);

    columns.push({
        name: "num_sales",
        label: "Count",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });

    return (
        <MuiDataTable
            data={rows}
            columns={columns}
            options={{
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                customToolbar: null,
                title: null,
                pagination: false,
                selectableRows: "none",
                responsive: "vertical",
                customRowRender:
                    isBrand ? renderBrandRow(rows, onEdit, onRowChecked) : renderCategoryRow(rows, onEdit, onRowChecked)
            }}
        />
    );
}

// BRAND ROWS

function appendBrandColumns(columns) {
    columns.push({
        name: "product_name",
        label: "Product",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
    columns.push({
        name: "sector",
        label: "Taxonomy",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
}

function renderBrandRow(rows, onEdit, onRowChecked) {
    return (_data, dataIndex, _rowIndex) => {
        return dataIndex >= rows.length ?
            <div></div> :
            <BrandRow
                key={`correction-table-${dataIndex}`}
                row={rows[dataIndex]}
                onEdit={onEdit}
                onRowChecked={onRowChecked}
            />;
    };
}

function BrandRow({ row, onEdit, onRowChecked }) {
    const classes = useStyles();
    const [showButtons, setShowButtons] = useState(false);
    const onHover = (show) => () => setShowButtons(show);

    return (
        <TableRow
            onMouseEnter={onHover(true)}
            onMouseLeave={onHover(false)}
            className={typeof row.correction === "undefined" ? classes.row : classes.rowEdited}
        >
            <TableCell style={cbColStyle}>
                <Checkbox
                    id={`table-checkbox-${row.data_id}`}
                    checked={typeof row.selected !== "undefined"}
                    onChange={(e) => onRowChecked(e.target.checked, row.data_id)}
                    style={cbStyle}
                    color="primary"
                />
            </TableCell>
            <TableCell>{output(row.merchant_name)}</TableCell>
            <TableCell>{output(row.receipt_short_description)}</TableCell>
            <TableCell className={classes.rowCellBold}>
                {typeof row.correction === "undefined" ? (
                    <Tooltip title={row.predicted_brand} arrow>
                        <div>{output(row.current_brand)}</div>
                    </Tooltip>
                ) : (
                    <Tooltip title={row.predicted_brand} arrow>
                        <div>{output(row.correction.catalog_brand)}&#42;</div>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell>
                <TruncatedString string={row.predicted_product_name} limit={32}></TruncatedString>
            </TableCell>
            <TableCell>
                <CategoryPath
                    classes={classes}
                    sector={row.current_sector}
                    department={row.current_department}
                    category={row.current_category}
                />
            </TableCell>
            <TableCell style={{ position: "relative" }}>
                <div style={{ visibility: showButtons ? "hidden" : "visible" }}>
                    {output(row.num_sales)}
                </div>
                {showButtons ? <EditButton classes={classes} onClick={() => onEdit(row)} /> : undefined}
            </TableCell>
        </TableRow >
    );
}

function CategoryPath({ classes, sector, department, category }) {
    if (isBlank(sector)) {
        return <div></div>;
    }

    if (isBlank(department)) {
        return (
            <div>
                <div className={classes.taxonomy}>{sector}</div>
            </div>
        );
    }

    if (isBlank(category)) {
        return (
            <div>
                <div className={classes.taxonomy}>{sector}</div>
                <Divider orientation="vertical" className={classes.divider} />
                <div className={classes.taxonomy}>{department}</div>
            </div>
        );
    }

    return (
        <div>
            <div className={classes.taxonomy}>{sector}</div>
            <Divider orientation="vertical" className={classes.divider} />
            <div className={classes.taxonomy}>{department}</div>
            <Divider orientation="vertical" className={classes.divider} />
            <div className={classes.taxonomy}>{category}</div>
        </div>
    );
}

// CATEGORY ROWS

function appendCategoryColumns(columns) {
    columns.push({
        name: "product_name",
        label: "Product",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
    columns.push({
        name: "current_sector",
        label: "Sector",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
    columns.push({
        name: "current_department",
        label: "Department",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
    columns.push({
        name: "current_category",
        label: "Major Category",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
}

function renderCategoryRow(rows, onEdit, onRowChecked) {
    return (_data, dataIndex, _rowIndex) => {
        return dataIndex >= rows.length ?
            <div></div> :
            <CategoryRow
                key={`corrector-table-${dataIndex}`}
                row={rows[dataIndex]}
                onEdit={onEdit}
                onRowChecked={onRowChecked}
            />;
    };
}

function CategoryRow({ row, onEdit, onRowChecked }) {
    const classes = useStyles();
    const [showButtons, setShowButtons] = useState(false);
    const onHover = (show) => () => setShowButtons(show);

    return (
        <TableRow
            onMouseEnter={onHover(true)}
            onMouseLeave={onHover(false)}
            className={typeof row.correction === "undefined" ? classes.row : classes.rowEdited}
        >
            <TableCell style={cbColStyle}>
                <Checkbox
                    id={`table-checkbox-${row.data_id}`}
                    checked={typeof row.selected !== "undefined"}
                    onChange={(e) => onRowChecked(e.target.checked, row.data_id)}
                    style={cbStyle}
                    color="primary"
                />
            </TableCell>
            <TableCell>{output(row.merchant_name)}</TableCell>
            <TableCell>{output(row.receipt_short_description)}</TableCell>
            <TableCell>{output(row.current_brand)}</TableCell>
            <TableCell>
                <TruncatedString string={row.predicted_product_name} limit={40}></TruncatedString>
            </TableCell>
            <TableCell className={classes.rowCellBold}>
                {typeof row.correction === "undefined" ? (
                    <div>{output(row.current_sector)}</div>
                ) : (
                    <Tooltip title={row.current_sector} arrow>
                        <div>{output(row.correction.sector)}&#42;</div>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell className={classes.rowCellBold}>
                {typeof row.correction === "undefined" ? (
                    <div>{output(row.current_department)}</div>
                ) : (
                    <Tooltip title={row.current_department} arrow>
                        <div>{output(row.correction.department)}&#42;</div>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell className={classes.rowCellBold}>
                {typeof row.correction === "undefined" ? (
                    <div>{output(row.current_category)}</div>
                ) : (
                    <Tooltip title={row.current_category} arrow>
                        <div>{output(row.correction.category)}&#42;</div>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell style={{ position: "relative" }}>
                <div style={{ visibility: showButtons ? "hidden" : "visible" }}>
                    {output(row.num_sales)}
                </div>
                {showButtons ? <EditButton classes={classes} onClick={() => onEdit(row)} /> : undefined}
            </TableCell>
        </TableRow>
    );
}

// HELPERS

function isBlank(string) {
    return string === undefined || string === null || string.length === 0;
}

function output(string) {
    return isBlank(string) ? "N/A" : string;
}

function TruncatedString({ string, limit }) {
    if (string.length > limit)
        return (
            <Tooltip title={string} arrow>
                <div>{`${string.substring(0, limit + 1)} ..`}</div>
            </Tooltip>
        );
    return output(string);
}

function setCellHeaderProps(value) {
    return {
        style: {
            fontWeight: "bold",
            color: "#212121",
        },
    };
};

function EditButton({ classes, onClick }) {
    return (
        <div className={classes.hoverButtons}>
            <Button
                component="span"
                size="small"
                onClick={onClick}
                color="primary"
                variant="contained"
            >
                Edit
            </Button>
        </div>
    );
}

const cbColStyle = {
    width: 0,
    padding: 0,
};

const cbStyle = {
    padding: 10,
    paddingLeft: 15,
};