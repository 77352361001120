import mixinBase from "./mixinBase";
import mixinReviewer from "./mixinReviewer";
import mixinCorrector from "./mixinCorrector";
import mixinSelection from "./mixinSelection";

import {
    STATE_BRAND_CHECK, STATE_TAXONOMY_CHECK,
    STATE_BRAND_CORRECTION, STATE_TAXONOMY_CORRECTION,
    QUERY_ORDER_BY_RSD_ID, QUERY_ORDER_BY_COUNT_ID
} from "./constants";

export class BrandReviewer {
    constructor(prefix) {
        mixinBase(
            this, prefix, STATE_BRAND_CHECK,
            "/brand-check", QUERY_ORDER_BY_COUNT_ID, true
        );
        mixinReviewer(this, true);
    }
}

export class CategoryReviewer {
    constructor(prefix) {
        mixinBase(
            this, prefix, STATE_TAXONOMY_CHECK,
            "/taxonomy-check", QUERY_ORDER_BY_COUNT_ID, true
        );
        mixinReviewer(this, false);
    }
}

export class BrandCorrector {
    constructor(prefix) {
        mixinBase(
            this, prefix, STATE_BRAND_CORRECTION,
            "/brand-correction", QUERY_ORDER_BY_RSD_ID, false
        );
        mixinSelection(this);
        mixinCorrector(this, true);
    }
}

export class CategoryCorrector {
    constructor(prefix) {
        mixinBase(
            this, prefix, STATE_TAXONOMY_CORRECTION,
            "/taxonomy-correction", QUERY_ORDER_BY_COUNT_ID, true
        );
        mixinSelection(this);
        mixinCorrector(this, false);
    }
}